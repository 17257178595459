import request from "../utils/request";
import Qs from "qs";

export const Sms = (query) => {
  return request({
    url: "invite-sms",
    method: "post",
    data: Qs.stringify(query),
  });
};

export const Register = (query) => {
  return request({
    url: "invite-register",
    method: "post",
    data: Qs.stringify(query),
  });
};
