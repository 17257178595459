<template>
  <div style="margin-top: 20%;">
    <div style="margin:auto; width: 80%; border: 1px solid #1989fa; padding: 15px; border-radius: 25px;">
      <van-form>
        <van-field
          v-model="loginForm.phone"
          label="手机"
          colon
          placeholder="请输入手机" />
        <van-field
          v-model="loginForm.password"
          type="password"
          label="密码"
          colon
          placeholder="请输入密码" />
        <div style="margin: 16px;">
          <van-button round type="info" size="small" plain @click="doLogin">
            登 录
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  import { Login } from '../api/login';
  import { Toast } from 'vant';
  export default {
    name: 'Home',
    data() {
      return {
        loginForm: {
	        phone: '',
            password: '',
        }
      }
    },
    methods: {
      doLogin() {
        Login(this.loginForm).then(res => {
	        Toast.success('登录成功');
	        localStorage.setItem("xd_partner_user_token", res.token);
	        localStorage.setItem("xd_partner_user_name", res.name);
	        this.$router.push('/');
        });
      },
    }
  }
</script>
