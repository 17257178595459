import request from '../utils/request';
import Qs from 'qs';

export const Login = query => {
	return request({
		url: 'login',
		method: 'post',
		data: Qs.stringify(query)
	});
};
