<template>


    <div>
        <van-empty
            :image="img"
            image-size="80"
            description=""
        />
        <div style="font-size: 20px; color: green">
            注册成功
        </div>
        <div style="font-size: 18px; color: red; width: 80%; margin: auto; padding-top: 20px; text-algin: left">
            请打开app，用刚注册的手机号登录，激活账号吧！

        </div>
        <div style="font-size: 18px; color: red; width: 80%; margin: auto; padding-top: 20px; text-algin: left">
            若还未下载app，请先前往应用市场 搜索【嗨皮直播】下载，logo如上图
            <p style="color: #000000; padding-top: 25px;">或直接点下下面链接下载：</p>
            <p>
                <a href="https://app.so.com/detail/index?pname=com.dwhl.zy&id=4644154">360应用市场</a>
            </p>
            <p>
                <a href="https://shouji.baidu.com/detail/5000029618">百度应用市场</a>
            </p>
            <p>
                <a href="https://sj.qq.com/appdetail/com.dwhl.zy">应用宝</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: "RegSuccess",
  data() {
    return {
      img: require("../assets/logo.png"),
    };
  },
}
</script>
