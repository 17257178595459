<template>
  <div class="login-page">
    <div class="poster"></div>
    <div class="form-warp">
      <div class="form-content">

        <van-field class="form-item" type="number" v-model="phone" placeholder="请输入手机号" maxlength="11">
        </van-field>
        <van-field class="form-item" type="digit" v-model="code" placeholder="请输入验证码" maxlength="4">
          <template #button>
            <van-button class="code-btn" size="small" round  :style="codeStyle" :disabled="s < 60" @click="getCode">{{
              codeText
            }}</van-button>
          </template>
        </van-field>
        <div class="btn">
          <van-image class="img" :src="require('../assets/btn.png')" @click="login"/>
        </div>
      </div>
      <div class="copy-right">嗨皮直播交友 南宁多维互联科技有限公司 &copy; 2023 - 2024 版权所有 <br/> 桂ICP备2022003788号</div>
    </div>
    <van-number-keyboard :show="phoneKeyboardShow" @blur="phoneKeyboardShow = false" @input="phoneInput"
      @delete="phoneDelete" />
    <van-number-keyboard :show="codeKeyboardShow" @blur="codeKeyboardShow = false" @input="codeInput"
      @delete="codeDelete" />
  </div>
</template>

<script>
import { isPhone } from "../utils/regular";
import { Sms, Register } from '../api/invite';
import { getUrlQuery } from '../utils/common';
import { Toast } from 'vant';

const COUNT = 60; // 倒计时长

export default {
  name: "Invite",
  mounted() {
    let exp = getUrlQuery("exp");
    let now = Math.floor(Date.now() / 1000);
    if (exp < now) {
      Toast("链接已过期");
      this.expired = true;
      return;
    }
    this.regCode = getUrlQuery("regCode");
  },
  data() {
    return {
      phone: "",
      code: "",
      codeText: "获取验证码",
      phoneKeyboardShow: false,
      codeKeyboardShow: false,
      s: COUNT,
      show: false,
      regCode: '',
      exp: '',
      expired: false

    };
  },
  computed: {
    codeStyle() {
      return this.s < 60 ? {backgroundColor: '#FFECF5', color: 'red'} : {backgroundColor: '#FFECF5', color: '#FE5AAA'}
    }
  },
  methods: {

    // 获取验证码
    async getCode() {
      // 加锁
      if (this.expired) {
        Toast("链接已过期");
        return;
      }
      if (this.s < COUNT) return;
      if (!isPhone(this.phone.replaceAll(' ', ''))) {
        Toast("手机号格式有误");
        return;
      }
      try {
        await Sms({
          phone: this.phone.replaceAll(' ', ''),
          partner_code: this.regCode
        })
        Toast("验证码发送成功，请注意查收");
        let timer = setInterval(() => {
          this.s--;
          this.codeText = `${this.s}s`;
          if (this.s <= 0) {
            this.s = COUNT;
            this.codeText = "获取验证码";
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {

      }
    },

    // 登录
    async login() {
      if (this.expired) {
        Toast("链接已过期");
        return;
      }
      if (!this.phone) {
        Toast("请输入手机号码");
        return;
      }
      if (!this.code) {
        Toast("请输入验证码");
        return;
      }
      if (!isPhone(this.phone.replaceAll(' ', ''))) {
        Toast("手机号格式有误");
        return;
      }

      await Register({
	    phone: this.phone.replaceAll(' ', ''),
	    code: this.code,
	    partner_code: this.regCode
      }).then(res => {
	    Toast("注册成功");
        this.$router.push({ path: '/regSuccess'});
      });
    },
  }
};
</script>


<style>
.popup-cus-content {
  padding: 15px;
  overflow-y: scroll;
}

.van-popup p,
.van-popup span,
.van-popup i {
  font-size: 16px;
  color: #999999;
}
</style>

<style>
.login-page {
  background-color: #F4C9F8;
  position: relative;

  .poster {
    height: 600px;
    background: url('../assets/login_posrter1.png') no-repeat center;
    background-size: 100% auto;
  }

  .form-warp {
    margin: 0 10px;
    margin-top: -24px;
    border-radius: 10px;
    overflow: hidden;

    .head {
      height: 80px;
      background-color: #FEF4FF;
      display: flex;
      align-items: center;
      padding: 0 15px;

      .img-box {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 12px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;

        .img {
          width: 100%;
          height: 100%;
        }
      }

      .text-box {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: bold;

        .nickname {
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span {
            color: red;
          }
        }
      }

    }

    .form-content {
      padding: 20px 15px;
      background-color: #ffffff;
      border-radius: 0 0 10px 10px;

      .form-item {
        border-radius: 24px;
        border: 1px solid #F3F3F3;
        height: 47px;
        display: flex;
        align-items: center;
        .van-cell__value {
          height: 47px;
          .van-field__body {
            height: 100%;
          }
        }

        &::after {
          border-bottom: 0;
        }

        &:nth-child(2) {
          padding: 6px 6px 6px 20px;
          margin-top: 12px;
          padding-right: 5px;
        }

        .code-btn {
          width: 103px;
          height: 36px;
          border: unset;
          font-weight: bold;
        }
      }

      .btn {
        width: 270px;
        height: 45px;
        margin: 0 auto;
        margin-top: 50px;
      }
    }
  }
  .list-img {
    margin-top: 15px;
  }
  .copy-right {
    color: rgba(22, 18, 62, .5);
    text-align: center;
    margin: 20px 0 80px 0;
  }

}</style>
